<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
    >
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/pages/forgot-password.png"
                alt="login"
                class="mx-auto"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg"
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">
                    {{ lang.resetPassword.title[languageSelected] }}
                  </h4>
                  <p>
                    {{ lang.resetPassword.description[languageSelected] }}
                  </p>
                </div>

                <vs-input
                  type="email"
                  :label-placeholder="
                    lang.resetPassword.form.email[languageSelected]
                  "
                  v-model="email"
                  class="w-full mb-8"
                  :danger="!emailValid"
                  :dangerText="
                    lang.resetPassword.form.error.invalidEmail[languageSelected]
                  "
                  :disabled="sending"
                />
                <vs-button
                  type="border"
                  to="/pages/login"
                  class="px-4 w-full md:w-auto"
                  :disabled="sending"
                  >{{
                    lang.resetPassword.backToLogin[languageSelected]
                  }}</vs-button
                >
                <vs-button
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  @click="resetPassword"
                  :disabled="sending"
                  >{{
                    lang.resetPassword.recoverPassword[languageSelected]
                  }}</vs-button
                >
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Validations from '../../store/Validations'

import konecta from '@/services/konecta'

export default {
  name: 'ResetPassword',
  data() {
    return {
      email: '',
      emailValid: true,
      sending: false,
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    responseToast(title, text, color) {
      this.$vs.notify({
        title,
        text,
        color
      })
    },
    resetPassword() {
      this.emailValid = Validations.isValidEmail(this.email)

      if (!this.emailValid) {
        return
      }

      let data = {
        email: this.email,
      }
      this.sending = true
      konecta
        .post('/user/forgot-password', data)
        .then(response => {
          this.response = response
          this.sending = false
          this.email = ''
          this.responseToast(
            this.lang.resetPassword.form.response.success.title[
              this.languageSelected
            ],
            this.lang.resetPassword.form.response.success.description[
              this.languageSelected
            ],
            'success'
          )
        })
        .catch(error => {
          if (
            error
            && error.response
            && error.response.status
            && error.response.status < 500
          ) {
            this.responseToast(
              this.lang.resetPassword.form.response.success.title[
                this.languageSelected
              ],
              this.lang.resetPassword.form.response.success.description[
                this.languageSelected
              ],
              'success'
            )
          } else {
            console.error(error);
            this.responseToast(
              this.lang.resetPassword.form.response.error.title[
                this.languageSelected
              ],
              this.lang.resetPassword.form.response.error.description[
                this.languageSelected
              ],
              'error'
            )
          }
          this.sending = false
          this.email = ''
        })
    }
  }
}
</script>
